import * as React from 'react';
import { IGridLayoutProps } from '../Layout.types';

import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';
import { GridLayoutItem } from './GridLayoutItem';

import { st, classes } from './GridLayout.st.css';

export class GridLayout extends React.Component<IGridLayoutProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  render() {
    const { isMobile, isFluid } = this.context;
    const { ...formatProps } = this.props;
    return (
      <div className={st(classes.container)}>
        <ul
          className={st(classes.root, { mobileView: isMobile, fluid: isFluid })}
          data-hook="grid-layout"
          ref={this.props.listRef}
          tabIndex={-1}
          role="region list"
          aria-label={this.props.label}
        >
          {this.props.items.map((item, index) => {
            const handleClick = (e: React.MouseEvent<HTMLElement>) =>
              this.props.onItemClick && this.props.onItemClick(e, item, index);

            const handleLinkClick = (e: React.MouseEvent<HTMLElement>) =>
              this.props.onItemLinkClick &&
              this.props.onItemLinkClick(e, item, index);

            return (
              <GridLayoutItem
                {...formatProps}
                key={index}
                item={item}
                onClick={handleClick}
                onLinkClick={handleLinkClick}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}
